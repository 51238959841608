import { ButtonStylesParams, MantineThemeOverride, Variants, rem } from "@mantine/core"
// import { Lato } from "next/font/google"

// const lato = Lato({
//   subsets: ["latin", "latin-ext"],
//   weight: ["100", "300", "400", "700", "900"],
//   style: ["normal"],
// })

type Size = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900

export type CustomTheme = MantineThemeOverride & {
  other?: {
    size: Record<Size, string>
    paddingBlock?: Record<Size, string>
  }
}

export const themeConfig: CustomTheme = {
  fontFamily: "Lato, san-serif",
  colors: {
    black: ["#333", "#333", "#333", "#333", "#333", "#333", "#333", "#333", "#333", "#000"],
    neutral: [
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
      "#FFFFFF",
    ],
    brand: [
      "#17A148",
      "#1CB74D",
      "#1EAD46",
      "#22A445",
      "#229E47",
      "#1FAC4A",
      "#19B04E",
      "#20A84B",
      "#19B449",
      "#1B9C45",
    ],
    tdf: ["#ebfbee", "#d3f9d8", "#b2f2bb", "#8ce99a", "#69db7c", "#51cf66", "#19B04E", "#37b24d", "#2f9e44", "#157E40"],
  },
  primaryColor: "tdf",
  primaryShade: 7,
  breakpoints: {
    xs: "36em",
    sm: "48em",
    md: "62em",
    lg: "75em",
    xl: "87.5em",
  },
  components: {
    Container: {
      styles(theme, params, context) {
        return {
          root: {
            paddingLeft: "1rem",
            paddingRight: "1rem",
            maxWidth: "100%",
            [theme.fn.largerThan("xs")]: {
              maxWidth: rem(540),
            },
            [theme.fn.largerThan("sm")]: {
              maxWidth: rem(720),
            },
            [theme.fn.largerThan("md")]: {
              maxWidth: rem(960),
            },
            [theme.fn.largerThan("lg")]: {
              maxWidth: rem(1180),
            },
            [theme.fn.largerThan("xl")]: {
              maxWidth: rem(1320),
            },
          },
        }
      },
    },
    Button: {
      styles(theme, params: ButtonStylesParams, { variant }) {
        return {
          inner: {
            position: "relative",
            zIndex: 2,
          },
          root: {
            borderWidth: "0px",
            transition: "all .5s",
            ...(variant ? getCustomButtonAttributes(variant, params) : {}),
          },
        }
      },
    },
  },
  other: {
    size: {
      100: ".25rem",
      200: ".5rem",
      300: ".75rem",
      400: "1rem",
      500: "1.25rem",
      600: "1.5rem",
      700: "1.75rem",
      800: "2rem",
      900: "4rem",
    },
  },
  globalStyles: theme => ({
    body: {
      margin: 0,
      color: theme.colors.black[0],
    },

    a: {
      color: theme.colors?.brand[0],
      textDecoration: "none",
      transition: "background .5s, color .5s",
      "&:hover": {
        color: theme.colors.brand[2],
        filter: "brightness(1.1)",
      },
    },
    ".cursor-pointer": {
      "&:hover": {
        cursor: "pointer",
      },
    },

    button: {
      transition: "background .5s, color .5s",
    },

    "@keyframes bounce-in": {
      "0%": { opacity: 0, transform: "scale(0.1)" },
      "100%": { opacity: 1, transform: "scale(1)" },
    },
    "@keyframes bounce-out": {
      "0%": { opacity: 1, transform: "scale(1)" },
      "100%": { opacity: 0, transform: "scale(0.1)" },
    },
  }),
}

interface ColorTypes {
  backgroundColor?: string
  color?: string
  borderColor?: string
  borderWidth?: string
}

interface CustomStyles extends ColorTypes {
  "&:hover"?: ColorTypes
  "&:before"?: ColorTypes
  "&:after"?: ColorTypes
  "&:hover:before"?: ColorTypes
  "&:hover:after"?: ColorTypes
}

const getCustomButtonAttributes = (
  variant: Variants<"filled" | "outline" | "light" | "white" | "default" | "subtle" | "gradient"> | undefined,
  params: Record<string, any>,
): CustomStyles | {} => {
  switch (variant) {
    case "filled": {
      return {
        background: "#18af4e",
        border: 0,
        color: "#FFFFFF",
        "&:hover": {
          filter: "brightness(1.1)",
        },
        "&:before": {
          content: '""',
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: 0,
          transition: "all .3s",
          borderRadius: params?.radius || 4,
          boxSizing: "border-box",
          backgroundColor: "#18af4e",
        },
        "&:after": {
          content: '""',
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: 0,
          opacity: 0,
          transition: "all .3s",
          border: "2px solid #18af4e",
          transform: "scale(1.2)",
          borderRadius: params.radius || 8,
          boxSizing: "border-box",
        },
        "&:hover:before": {
          opacity: 0,
          transform: "scale(0.5)",
        },
        "&:hover:after": {
          opacity: 1,
          transform: "scale(1)",
        },
      }
    }
    case "outline": {
      return {
        backgroundColor: "transparent",
        borderColor: "#ffffff",
        borderWidth: "0",
        color: "#ffffff",
        position: "relative",
        "&:hover": {
          backgroundColor: "#18af4e",
          borderColor: "#18af4e",
          color: "#ffffff",
          filter: "brightness(1.1)",
        },
        "&:before": {
          content: '""',
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: 0,
          border: "1px solid #fff",
          transition: "all .3s",
          borderRadius: params?.radius || 4,
          boxSizing: "border-box",
        },
        "&:after": {
          content: '""',
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: 0,
          opacity: 0,
          transition: "all .3s",
          borderRadius: params?.radius || 4,
          backgroundColor: "#18af4e",
          boxSizing: "border-box",
          transform: "scale(0.5)",
        },
        "&:hover:before": {
          opacity: 0,
          transform: "scale(1.2)",
        },
        "&:hover:after": {
          opacity: 1,
          transform: "scale(1)",
        },
      }
    }
    case "default":
      return {
        backgroundColor: "#f3f3f3",
        borderColor: "#f3f3f3",
        color: "#333",
        "&:hover": {
          backgroundColor: "#f3f3f3",
          borderColor: "#f3f3f3",
          color: "#18af4e",
          filter: "brightness(1.02)",
        },
      }

    default:
      return {}
  }
}
