import client from "@/apolo-client"
import { themeConfig } from "@/layout/theme"
import "@/styles/globals.css"
import { ApolloProvider } from "@apollo/client"
import { MantineProvider } from "@mantine/core"
import { appWithTranslation } from "next-i18next"
import type { AppProps } from "next/app"
import NextNProgress from "nextjs-progressbar"
import nextI18NextConfig from "../../next-i18next.config.js"

import "@/styles/ck-content.css"
import "normalize.css"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

function App(props: AppProps) {
  const { Component, pageProps } = props

  return (
    <main>
      <ApolloProvider client={client}>
        <MantineProvider withCSSVariables withGlobalStyles withNormalizeCSS theme={themeConfig}>
          <NextNProgress color="#18af4e" />
          <Component {...pageProps} />
        </MantineProvider>
      </ApolloProvider>
    </main>
  )
}

export default appWithTranslation(App, nextI18NextConfig)
