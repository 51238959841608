import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_URL,
  headers: {
    Authorization: `Bearer ${process.env.TOKEN}`,
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.debug("[DEBUG] ~ file: apolo-client.ts:12 ~ graphQLErrors:", graphQLErrors)
  }

  if (networkError) {
    console.debug("[DEBUG] ~ file: apolo-client.ts:12 ~ networkError:", networkError)
  }
})

const appLink = from([errorLink, httpLink])

const client = new ApolloClient({
  link: appLink,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${process.env.TOKEN}`,
  },
})

export default client
